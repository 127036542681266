import {
  LOADING,
  ERROR,
  SYNC_UNITS_OCCUPANCY,
  SYNC_CURRENT_SECTORS,
  SET_CURRENT_SHIFT,
  SET_REDISTRIBUTION_FROM,
  SET_REDISTRIBUTION_TO,
  CLEAR_SECTORS_REDISTRIBUTION
} from '@store/types/unitsOccupancyTypes'

const initialState = {
  loading: false,
  error: null,
  categories: [],
  bedsTypes: [],
  sectorsByShifts: [],
  currentShift: null,
  currentSectors: [],
  sectorIdRedistributionFrom: null,
  sectorIdRedistributionTo: null,
  updatedAt: null
}

const unitsOccupancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case SYNC_UNITS_OCCUPANCY:
      return {
        ...state,
        loading: false,
        error: null,
        categories: action.categories,
        bedsTypes: action.bedsTypes,
        sectorsByShifts: action.sectorsByShifts,
        updatedAt: action.updatedAt
      }
    case SYNC_CURRENT_SECTORS:
      return {
        ...state,
        currentSectors: action.currentSectors
      }
    case SET_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: action.currentShift
      }
    case SET_REDISTRIBUTION_FROM:
      return {
        ...state,
        sectorIdRedistributionFrom: action.sectorId
      }
    case SET_REDISTRIBUTION_TO:
      return {
        ...state,
        sectorIdRedistributionTo: action.sectorId
      }
    case CLEAR_SECTORS_REDISTRIBUTION:
      return {
        ...state,
        sectorIdRedistributionFrom: null,
        sectorIdRedistributionTo: null
      }
    default:
      return state
  }
}

export default unitsOccupancyReducer
