import { lazy } from 'react'

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/units-occupancy'

// ** Merge Routes
const Routes = [
  /*{
    path: '/home',
    component: lazy(() => import('../../views/Home'))
  },*/
  {
    path: '/units-occupancy',
    exact: true,
    component: lazy(() => import('../../views/units-occupancy/UnitsOccupancyView'))
  },
  {
    path: '/units-occupancy/redistribution',
    exact: true,
    component: lazy(() => import('../../views/units-occupancy/RedistributionView'))
  },
  {
    path: '/login',
    component: lazy(() => import('../../views/auth/Login')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/forgot-password',
    component: lazy(() => import('../../views/auth/ForgotPassword')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/reset-password/:token',
    component: lazy(() => import('../../views/auth/ResetPassword')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/error',
    component: lazy(() => import('../../views/Error')),
    layout: 'BlankLayout'
  }
]

export { DefaultRoute, TemplateTitle, Routes }
