export const LOADING = 'LOADING'
export const ERROR = 'ERROR'

export const SYNC_UNITS_OCCUPANCY = 'SYNC_UNITS_OCCUPANCY'
export const SYNC_CURRENT_SECTORS = 'SYNC_CURRENT_SECTORS'

export const SET_CURRENT_SHIFT = 'SET_CURRENT_SHIFT'
export const SET_REDISTRIBUTION_FROM = 'SET_REDISTRIBUTION_FROM'
export const SET_REDISTRIBUTION_TO = 'SET_REDISTRIBUTION_TO'

export const CLEAR_SECTORS_REDISTRIBUTION = 'CLEAR_SECTORS_REDISTRIBUTION'
